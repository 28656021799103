import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import {
  getDataSectionWellcome,
  updateDataHome,
} from "../../../services/home.services";

import InputAdmin from "../../../components/Input/Input";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const EditWellcome = () => {
  const methods = useForm();
  const reset = methods.reset;
  let navigate = useNavigate();

  const onSubmit = async (data) => {
    updateDataHome(data, "nq2JZTM4yt2G5G32ziL7").then((response) => {
      navigate("/edit-home", { replace: true });
    });
  };

  const cancel = () => {
    navigate("/edit-home", { replace: true });
  };

  useEffect(() => {
    let data;
    getDataSectionWellcome().then((response) => {
      data = response;
      reset({
        title_es: data.title_es,
        title_en: data.title_en,
        title_color_1_es: data.title_color_1_es,
        title_color_1_en: data.title_color_1_en,
        title_color_2_es: data.title_color_2_es,
        title_color_2_en: data.title_color_2_en,
        description_es: data.description_es,
        description_en: data.description_en,
      });
    });
  }, [reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Editar "Wellcome Section"</Title>
          <FormContainer className="row">
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Titulo Color 1 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`title_color_1_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Titulo Color 1 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`title_color_1_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Titulo Color 2 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`title_color_2_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Titulo Color 2 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`title_color_2_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Descripción Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`description_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Descripción Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`description_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default EditWellcome;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
