import { Form } from "react-bootstrap";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { useState } from "react";

const InputAdmin = ({
  ClassNameGroup = "",
  ClassNameInput = "",
  label = "",
  fsLabel = "1em",
  placeholder = "",
  maxLength = 256,
  type = "text",
  as = "input",
  height = "auto",
  inputName,
  disabled,
}) => {
  const { register } = useFormContext();

  const [inputLenght] = useState("");
  return (
    <Form.Group className={`mb-0 ${ClassNameGroup}`}>
      {label !== "" ? <Label fsLabel={fsLabel}>{label}</Label> : <></>}
      <Form.Control
        {...register(`${inputName}`)}
        as={as}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        style={{ height: height }}
        className={ClassNameInput}
        disabled={disabled}
      />
      {maxLength !== 256 ? (
        <Counter className="text-muted">{`${inputLenght}/${maxLength}`}</Counter>
      ) : (
        <></>
      )}
    </Form.Group>
  );
};

export default InputAdmin;

const Counter = styled.small`
  margin-top: 0.3rem;
  text-align: end;
  font-size: 0.7rem;
  display: block;
`;

const Label = styled.label`
  font-size: ${(props) => props.fsLabel};
  margin-bottom: 0.2em;
`;
