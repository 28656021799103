import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import ButtonEdit from "../../components/ButtonEdit/ButtonEdit.jsx";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const EditGalleryMenu = () => {
  let navigate = useNavigate();
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <Title>Editar La Galeria</Title>
          <Button
            onClick={() => navigate("/edit-home", { replace: true })}
            variant="info"
            size="sm"
          >
            Volver
          </Button>
          <ButtonsContainer>
            <a href="/list-gallery">
              <ButtonEdit
                label="Editar Fotos"
                bgColor="#f5abcb"
                bgColorHover="#f5ab"
              ></ButtonEdit>
            </a>
            <a href="/list-video-gallery">
              <ButtonEdit
                label="Editar Videos"
                bgColor="#9ed7c8"
                bgColorHover="#5ed7c8"
              ></ButtonEdit>
            </a>
          </ButtonsContainer>
        </div>
      </div>
    </div>
  );
};

export default EditGalleryMenu;

const Title = styled.h1`
  margin: 60px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
`;
