import { DataPagesProvider } from "../context/DataHome";
import Footer from "../components/SectionPages/Footer.jsx";
import Header from "../components/SectionPages/Header.jsx";
import PageTopLine from "../components/SectionPages/PageTopLine.jsx";
import React from "react";
import SectionCopyRight from "../components/SectionPages/SectionCopyRight.jsx";

const Layout = (props) => {
  const { children } = props;
  return (
    <>
      <PageTopLine />
      <Header />
      {children}
      <Footer />
      <SectionCopyRight />
    </>
  );
};

export default Layout;
