import React, { useState } from "react";
import {
  getGalleryList,
  getGalleryVideosList,
} from "../services/home.services";

import SectionGalery from "../components/SectionPages/SectionGalery";
import { useEffect } from "react";

const Gallery = () => {
  const [dataGallery, setDataGallery] = useState(null);
  const [dataGalleryVideos, setDataGalleryVideos] = useState(null);

  useEffect(() => {
    getGalleryList().then((response) => {
      setDataGallery(response);
    });
    getGalleryVideosList().then((response) => {
      setDataGalleryVideos(response);
    });
  }, []);
  return (
    <SectionGalery
      dataGallery={dataGallery}
      dataGalleryVideo={dataGalleryVideos}
    />
  );
};

export default Gallery;
