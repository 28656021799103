import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import { FaArrowLeft } from "react-icons/fa";
import InputAdmin from "../../../components/Input/Input";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Create = (props) => {
  const methods = useForm();
  let navigate = useNavigate();

  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("title_es", data.title_es);
    formdata.append("title_en", data.title_en);
    formdata.append("content_1_es", data.content_1_es);
    formdata.append("content_1_en", data.content_1_en);
    formdata.append("content_2_es", data.content_2_es);
    formdata.append("content_2_en", data.content_2_en);
    formdata.append("image", data.image[0]);
    formdata.append("autor", data.autor);
  };

  const cancel = () => {
    navigate("/list-blog", { replace: true });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Crear Articulo para el Blog</Title>
          <FormContainer className="row">
            <div className="col-md-12">
              <Button
                onClick={() => navigate("/list-blog", { replace: true })}
                variant="primary"
                size="sm"
              >
                <FaArrowLeft />
              </Button>{" "}
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 1 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_1_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 1 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_1_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 2 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_2_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 2 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_2_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 3 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_3_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 3 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_3_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 4 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_4_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 4 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_4_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Portada"}
                fsLabel={"2rem"}
                inputName={`image`}
                type="file"
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default Create;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
