import "./public/css/main.css";
import "./public/css/bootstrap.min.css";
import "./public/css/fonts.css";
import "./public/css/animations.css";

import { BrowserRouter } from "react-router-dom";
import { DataPagesProvider } from "./context/DataHome";
import React from "react";
import ReactDOM from "react-dom/client";
import RoutesApp from "./routes/Routes.js";
import { UserProvider } from "./context/UserContext";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <UserProvider>
      <DataPagesProvider>
        <BrowserRouter>
          <RoutesApp></RoutesApp>
        </BrowserRouter>
      </DataPagesProvider>
    </UserProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
