import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { FaArrowLeft } from "react-icons/fa";
import InputAdmin from "../../../components/Input/Input";
import React from "react";
import { galleryVideoAdd } from "../../../services/home.services";
import { storage } from "../../../firebase";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const CreateVideoGallery = () => {
  const methods = useForm();
  let navigate = useNavigate();

  const upLoadFile = async (file) => {
    console.log(file);
    const storageRef = ref(storage, v4() + file.name);
    await uploadBytes(storageRef, file);
    const url = getDownloadURL(storageRef);
    return url;
  };

  const onSubmit = (form) => {
    upLoadFile(form.video[0]).then((response) => {
      console.log(response);
      let data = {
        title_en: form.title_en,
        title_es: form.title_es,
        video: response,
      };
      galleryVideoAdd(data).then((response) => {
        navigate("/list-video-gallery", { replace: true });
      });
    });
  };

  const cancel = () => {
    navigate("/list-video-gallery", { replace: true });
  };
  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Cargar Video a la Galeria</Title>
          <FormContainer className="row">
            <div className="col-md-12">
              <Button
                onClick={() =>
                  navigate("/list-video-gallery", { replace: true })
                }
                variant="primary"
                size="sm"
              >
                <FaArrowLeft />
              </Button>{" "}
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Video"}
                fsLabel={"2rem"}
                inputName={`video`}
                type="file"
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default CreateVideoGallery;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
