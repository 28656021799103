import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { getAbout, updateAbout } from "../../../services/home.services";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { FaArrowLeft } from "react-icons/fa";
import InputAdmin from "../../../components/Input/Input";
import { storage } from "../../../firebase";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const EditAbout = (props) => {
  const methods = useForm();
  let navigate = useNavigate();
  const reset = methods.reset;

  const upLoadFile = async (file) => {
    console.log(file);
    const storageRef = ref(storage, v4() + file.name);
    await uploadBytes(storageRef, file);
    const url = getDownloadURL(storageRef);
    return url;
  };

  const onSubmit = (form) => {
    if (form.image[0] != "h") {
      upLoadFile(form.image[0]).then((response) => {
        let dataForm = {
          title_es: form.title_es,
          title_en: form.title_en,
          content_1_es: form.content_1_es,
          content_1_en: form.content_1_en,
          content_2_es: form.content_2_es,
          content_2_en: form.content_2_en,
          content_3_es: form.content_3_es,
          content_3_en: form.content_3_en,
          content_4_es: form.content_4_es,
          content_4_en: form.content_4_en,
          image: response,
        };

        updateAbout(dataForm, "D8AE1S1HwBjYUfc14ljF").then((response) => {
          navigate("/admin-page", { replace: true });
        });
      });
    } else {
      let dataForm = {
        title_es: form.title_es,
        title_en: form.title_en,
        content_1_es: form.content_1_es,
        content_1_en: form.content_1_en,
        content_2_es: form.content_2_es,
        content_2_en: form.content_2_en,
        content_3_es: form.content_3_es,
        content_3_en: form.content_3_en,
        content_4_es: form.content_4_es,
        content_4_en: form.content_4_en,
        image: form.image,
      };
      updateAbout(dataForm, "D8AE1S1HwBjYUfc14ljF").then((response) => {
        navigate("/admin-page", { replace: true });
      });
    }
  };

  const cancel = () => {
    navigate("/admin-page", { replace: true });
  };

  useEffect(() => {
    getAbout("D8AE1S1HwBjYUfc14ljF").then((response) => {
      let data = response;
      reset({
        title_es: data.title_es,
        title_en: data.title_en,
        content_1_es: data.content_1_es,
        content_1_en: data.content_1_en,
        content_2_es: data.content_2_es,
        content_2_en: data.content_2_en,
        content_3_es: data.content_3_es,
        content_3_en: data.content_3_en,
        content_4_es: data.content_4_es,
        content_4_en: data.content_4_en,
        image: data.image,
      });
    });
  }, [reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Editar Acerca de Nosotros</Title>
          <FormContainer className="row">
            <div className="col-md-12">
              <Button
                onClick={() => navigate("/admin-page", { replace: true })}
                variant="primary"
                size="sm"
              >
                <FaArrowLeft />
              </Button>{" "}
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 1 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_1_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 1 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_1_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 2 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_2_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 2 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_2_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 3 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_3_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 3 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_3_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 4 Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_4_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Contenido Parrafo 4 Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`content_4_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Portada"}
                fsLabel={"2rem"}
                inputName={`image`}
                type="file"
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default EditAbout;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
