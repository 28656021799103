import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { getBlogArticlesById } from "../services/home.services";
import styled from "styled-components";
import { useDataPages } from "../context/DataHome";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const BlogArticle = () => {
  const { lenguageSelected } = useDataPages();
  console.log(lenguageSelected);
  const [data, setData] = useState(null);
  let navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();

  useEffect(() => {
    getBlogArticlesById(id).then((response) => {
      setData(response);
    });
  }, [lenguageSelected]);
  return (
    <>
      <TitleBlog>
        <h3 className="poppins">
          <span className="pink">A</span>B
          <span className="light-green">Y </span>
          <span className="turquoise">B</span>
          <span className="pink">l</span>
          <span className="light-green">o</span>
          <span className="turquoise">g</span>
        </h3>
      </TitleBlog>
      <ContentCard>
        {data && (
          <>
            <ImageContainer>
              <Image src={data.image} alt="" />
            </ImageContainer>
            <TextContainer>
              <h2>
                {lenguageSelected === "es" ? data.title_es : data.title_en}
              </h2>
              <SubTitle>
                {lenguageSelected === "es"
                  ? data.sub_title_card_es
                  : data.sub_title_card_en}
              </SubTitle>
              <Description>
                {lenguageSelected === "es"
                  ? data.content_1_es
                  : data.content_1_en}
              </Description>
              <Description>
                {lenguageSelected === "es"
                  ? data.content_2_es
                  : data.content_2_en}
              </Description>
              <Description>
                {lenguageSelected === "es"
                  ? data.content_3_es
                  : data.content_3_en}
              </Description>
              <Description>
                {lenguageSelected === "es"
                  ? data.content_4_es
                  : data.content_4_en}
              </Description>
              <p> {data.autor}</p>
              <Button
                onClick={() => navigate("/blog", { replace: true })}
                variant="primary"
              >
                {lenguageSelected === "es" ? "Volver" : "Back"}
              </Button>{" "}
            </TextContainer>
          </>
        )}
      </ContentCard>
    </>
  );
};

export default BlogArticle;

const ContentCard = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 5% 10%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const TextContainer = styled.div`
  width: 50%;
  padding: 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px;
  }
`;

const Description = styled.p`
  font-size: 15px;
  line-height: 20px;
`;

const SubTitle = styled.p`
  font-size: 12px;
  font-style: italic;
  line-height: 20px;
  margin-bottom: 25px;
`;

const Image = styled.img`
  width: 100%;
  margin-top: 40px;
`;

const TitleBlog = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
