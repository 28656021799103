import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  projectId: "aby-daycare",
  appId: "1:412436317479:web:e42f75d015acaca2829502",
  storageBucket: "aby-daycare.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyCEFlBm9rEAnF5ZzLSW8PZ4QPGNspMWg30",
  authDomain: "aby-daycare.firebaseapp.com",
  messagingSenderId: "412436317479",
  measurementId: "G-EXHT600HBG",
};

const app = firebase.initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = app.firestore();
