import { FaArrowLeft, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  deleteMemberTeam,
  getListDataSectionTeam,
} from "../../../services/home.services";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const TeamList = () => {
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  let navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/edit-team/${id}`, { replace: true });
  };

  const handleDelete = (id) => {
    deleteMemberTeam(id).then((response) => {
      setRefresh(!refresh);
      navigate(`/list-team`, { replace: true });
    });
  };

  useEffect(() => {
    getListDataSectionTeam().then((response) => {
      setData(response);
    });
  }, [refresh]);

  return (
    <TableContainer>
      <Title>
        <h2>Listado de Miembros del Equipo</h2>
      </Title>
      <div className="m-1 col-md-12">
        <ButtonAdd>
          <Button
            onClick={() => navigate("/edit-home", { replace: true })}
            variant="info"
            size="sm"
          >
            <FaArrowLeft />
          </Button>{" "}
          <Button
            onClick={() => navigate("/create-team-member", { replace: true })}
            variant="primary"
            size="sm"
          >
            <FaPlus /> Agregar
          </Button>{" "}
        </ButtonAdd>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#Id</th>
            <th>Nombre</th>
            <th>Titulo</th>
            <th>Foto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data && (
            <>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.title_es}</td>
                  <td>
                    <img src={item.image} width="100" alt="" />
                  </td>
                  <td>
                    <ActionsContainer>
                      <ButtonContainer>
                        <Button
                          onClick={() => handleEdit(item.id)}
                          className="m-3"
                          variant="primary"
                          size="sm"
                        >
                          <FaEdit />
                        </Button>
                      </ButtonContainer>
                      <ButtonContainer>
                        <Button
                          onClick={() => handleDelete(item.id)}
                          className="m-3"
                          variant="danger"
                          size="sm"
                        >
                          <FaTrashAlt />
                        </Button>
                      </ButtonContainer>
                    </ActionsContainer>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default TeamList;

const TableContainer = styled.div`
  padding: 50px 300px;
`;
const ActionsContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
`;

const ButtonAdd = styled.div`
  margin: 20px;
`;

const Title = styled.div`
  margin-bottom: 35px;
`;
