import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getMemberTeam, updateDataTeam } from "../../../services/home.services";

import { FaArrowLeft } from "react-icons/fa";
import InputAdmin from "../../../components/Input/Input";
import { storage } from "../../../firebase";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

const EditTeam = (props) => {
  const methods = useForm();
  const reset = methods.reset;
  let navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const upLoadFile = async (file) => {
    console.log(file);
    const storageRef = ref(storage, v4() + file.name);
    await uploadBytes(storageRef, file);
    const url = getDownloadURL(storageRef);
    return url;
  };

  const onSubmit = (form) => {
    if (form.image[0] != "h") {
      upLoadFile(form.image[0]).then((response) => {
        let dataForm = {
          name: form.name,
          title_es: form.title_es,
          title_en: form.title_en,
          description_es: form.description_es,
          description_en: form.description_en,
          image: response,
        };

        updateDataTeam(dataForm, id).then((response) => {
          navigate("/list-team", { replace: true });
        });
      });
    } else {
      let dataForm = {
        name: form.name,
        title_es: form.title_es,
        title_en: form.title_en,
        description_es: form.description_es,
        description_en: form.description_en,
        image: data.image,
      };
      updateDataTeam(dataForm, id).then((response) => {
        navigate("/list-team", { replace: true });
      });
    }
  };

  const cancel = () => {
    navigate("/list-team", { replace: true });
  };

  useEffect(() => {
    getMemberTeam(id).then((response) => {
      let data = response;
      setData(response);
      reset({
        id: data.id,
        name: data.name,
        title_es: data.title_es,
        title_en: data.title_en,
        description_es: data.description_es,
        description_en: data.description_en,
        image: data.image,
      });
    });
  }, [reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Editar "Team Section"</Title>
          <FormContainer className="row">
            <div className="col-md-12">
              <Button
                onClick={() => navigate("/list-team", { replace: true })}
                variant="primary"
                size="sm"
              >
                <FaArrowLeft />
              </Button>{" "}
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Nombre"}
                fsLabel={"2rem"}
                inputName={`name`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Foto"}
                fsLabel={"2rem"}
                inputName={`image`}
                type="file"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Descripción Español"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`description_es`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label="Descripción Ingles"
                fsLabel={"2rem"}
                placeholder="Ingresa el titulo"
                inputName={`description_en`}
                as="textarea"
                height="6em"
              ></InputAdmin>
            </div>
            <div className="col-md-2">
              <InputAdmin
                label="Id"
                fsLabel={"2rem"}
                inputName={"id"}
                disabled={true}
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default EditTeam;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
