import {
  FaArrowLeft,
  FaEdit,
  FaPlus,
  FaStickyNote,
  FaTrashAlt,
} from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  createNote,
  getNotesById,
  getUserById,
  updateNote,
} from "../services/home.services";
import { doc, getFirestore, setDoc } from "firebase/firestore";

import BrowserStorage from "../helpers/BrowserStorage";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Communication = () => {
  const [dataNotesList, setDataNotesList] = useState([]);
  const [responseNote, setResponseNote] = useState(null);
  const [show, setShow] = useState(false);
  const handleEdit = (id) => {};
  const [childName, setChildName] = useState("");
  const [note, setNote] = useState("");
  const [noteId, setNoteId] = useState("");
  const [title, setTitle] = useState("");
  const [dataUser, setDataUser] = useState(null);
  const [isReaded, setIsReaded] = useState(false);
  const db = getFirestore();

  const handleRead = () => {
    setIsReaded(true);
  };

  const handleDelete = (id) => {};

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setDataNote = (data) => {
    setNote(data.note);
    setNoteId(data.id);
    setTitle(data.title);
    setResponseNote(data.response);
  };

  const handleSubmit = () => {
    const note = {
      title: document.getElementById("title").value,
      note: document.getElementById("note").value,
      response: document.getElementById("responseNote").value,
    };
    const child_name = BrowserStorage.getLocalStorage("userData").child_name;

    const dataNote = {
      id: noteId,
      childName: child_name,
      readed: false,
      note: [note],
    };
    getNotesById(child_name).then((response) => {
      if (response) {
        let notes = response.note;
        let noteFilter = (notes.find((note) => note.id == noteId).response =
          responseNote);
        let data = {
          childName: child_name,
          readed: false,
          note: notes,
          id: noteId,
        };
        updateNote(data, child_name).then((response) => {
          handleClose();
        });
      } else {
        setDoc(docuRef, dataNote);
        handleClose();
      }
    });
    const docuRef = doc(db, "notes", childName);
  };

  useEffect(() => {
    let userId = BrowserStorage.getLocalStorage("user");
    getUserById(userId.uid).then((response) => {
      setDataUser(response);
      getNotesById(response.child_name).then((response) => {
        console.log(response.note);
        setDataNotesList(response.note);
      });
    });
  }, []);

  return (
    <Container>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Note</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {dataNotesList && (
            <>
              {dataNotesList.map((item, key) => (
                <tr key={key}>
                  <td>
                    <p>{item.title}</p>
                  </td>
                  <td>
                    <p>{item.note}</p>
                  </td>
                  <td>
                    <ActionsContainer>
                      <ButtonContainer>
                        <Button
                          onClick={() => {
                            console.log(item);
                            handleShow();
                            setDataNote(item);
                          }}
                          className="btn btn-primary m-3"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          variant="success"
                          size="sm"
                        >
                          <FaStickyNote />
                        </Button>
                      </ButtonContainer>
                    </ActionsContainer>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Nota para {childName}</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Título de la nota</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Ingresa el título"
                value={title}
                disabled={true}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nota</Form.Label>
              <Form.Control
                type="text"
                id="note"
                placeholder="Ingresa el contenido de la nota"
                as="textarea"
                rows={10}
                value={note}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Respuesta</Form.Label>
              <Form.Control
                type="text"
                id="responseNote"
                placeholder="Ingresa el contenido de la nota"
                as="textarea"
                rows={10}
                value={responseNote}
                onChange={(e) => setResponseNote(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="success" onClick={handleSubmit}>
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Communication;

const Container = styled.div`
  margin: 100px;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
`;

const P = styled.p`
  font-weight: bold;
`;
