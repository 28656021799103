import {
  FaArrowLeft,
  FaEdit,
  FaPlus,
  FaStickyNote,
  FaTrashAlt,
} from "react-icons/fa";
import React, { useEffect, useMemo, useState } from "react";
import {
  createNote,
  getChildList,
  getNotes,
  getNotesById,
  getUserById,
  updateNote,
} from "../../services/home.services";
import { doc, getFirestore, setDoc } from "firebase/firestore";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { v4 } from "uuid";

const NotesList = () => {
  const [dataChildList, setDataChildList] = useState(null);
  const [show, setShow] = useState(false);
  const handleEdit = (id) => {};
  const [childName, setChildName] = useState("");
  const [childList, setChildList] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState(null);
  const [title, setTitle] = useState("");
  const [response, setResponse] = useState(null);
  const [childNameTable, setChildNameTable] = useState(false);
  const db = getFirestore();

  const handleDelete = (id) => {};

  const handleClose = () => {
    setShow(false);
    setChildName(null);
    setNote(null);
    setTitle(null);
    setResponse(null);
  };
  const handleShow = () => setShow(true);

  const setDataNote = (data) => {
    setChildName(childNameTable ? childNameTable : null);
    setNote(data.note);
    setTitle(data.title);
    setResponse(data.response);
  };

  const handleSubmit = () => {
    const note = {
      title: document.getElementById("title").value,
      note: document.getElementById("note").value,
      response: "",
      id: v4(),
    };
    const childName = document.getElementById("childName").value;

    const dataNote = {
      childName: childName,
      readed: false,
      response: response,
      note: [note],
    };

    getNotesById(childName).then((response) => {
      if (response) {
        let notes = response.note;
        notes.push(note);
        let data = {
          childName: childName,
          readed: false,
          note: notes,
        };
        updateNote(data, childName).then((response) => {
          console.log(response);
        });
        handleClose();
      } else {
        setDoc(docuRef, dataNote);
        handleClose();
      }
    });
    const docuRef = doc(db, "notes", childName);
  };

  useMemo(() => {
    console.log("entre");
    getChildList().then((response) => {
      setChildList(response);
    });
    getNotesById(childNameTable).then((response) => {
      console.log(response.note);
      if (response.note) {
        setNoteList(response.note);
      } else {
        setNoteList([]);
      }
    });
  }, [childNameTable]);

  useEffect(() => {
    console.log("entre");
    getNotes().then((response) => {
      setDataChildList(response);
    });
    getNotesById(childNameTable).then((response) => {
      console.log(response.note);
      if (response.note) {
        setNoteList(response.note);
      } else {
        setNoteList([]);
      }
    });
  }, []);

  return (
    <Container>
      <Button className="mb-3 col-md-2" variant="success" onClick={handleShow}>
        Nueva Nota
      </Button>
      <Form.Select
        className="m-2 col-md-3"
        id="childNameTable"
        aria-label="Default select example"
        value={childNameTable}
        onChange={() =>
          setChildNameTable(document.getElementById("childNameTable").value)
        }
      >
        <option>Selecciona un niño(a)</option>
        {childList?.map((item, key) => (
          <option key={key} value={item.child_name}>
            {item.child_name} {item.child_last_name}
          </option>
        ))}
      </Form.Select>
      <div className="col-md-6"></div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Niño(a)</th>
            <th scope="col">Título</th>
            <th scope="col">Nota</th>
            <th scope="col">Acción</th>
          </tr>
        </thead>
        <tbody>
          {noteList.map((item, key) => (
            <tr key={key}>
              <td>
                {item.readed === true ? (
                  <p>{childNameTable}</p>
                ) : (
                  <P>{childNameTable}</P>
                )}
              </td>
              <td>
                {item.readed === true ? (
                  <p>{item.title}</p>
                ) : (
                  <P>{item.title}</P>
                )}
              </td>
              <td>
                {item.readed === true ? <p>{item.note}</p> : <P>{item.note}</P>}
              </td>
              <td>
                <ActionsContainer>
                  <ButtonContainer>
                    <Button
                      onClick={() => {
                        handleShow();
                        setDataNote(item);
                      }}
                      className="m-3"
                      variant="primary"
                      size="sm"
                    >
                      <FaEdit />
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button
                      onClick={() => handleDelete(item.id)}
                      className="m-3"
                      variant="danger"
                      size="sm"
                    >
                      <FaTrashAlt />
                    </Button>
                  </ButtonContainer>
                </ActionsContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Crear Nota para{" "}
            <Form.Select
              value={childName}
              onChange={(e) => setChildName(e.target.value)}
              id="childName"
              aria-label="Default select example"
            >
              <option>Selecciona un niño(a)</option>
              {childList?.map((item, key) => (
                <option key={key} value={item.child_name}>
                  {item.child_name} {item.child_last_name}
                </option>
              ))}
            </Form.Select>
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Título de la nota</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Ingresa el título"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nota</Form.Label>
              <Form.Control
                type="text"
                id="note"
                placeholder="Ingresa el contenido de la nota"
                as="textarea"
                rows={10}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Respuesta</Form.Label>
              <Form.Control
                type="text"
                id="note"
                placeholder="Ingresa el contenido de la nota"
                as="textarea"
                rows={10}
                value={response}
                disabled={true}
                onChange={(e) => setResponse(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="success" onClick={handleSubmit}>
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default NotesList;

const Container = styled.div`
  margin: 100px;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
`;

const P = styled.p`
  font-weight: bold;
`;
