import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import ButtonEdit from "../../components/ButtonEdit/ButtonEdit.jsx";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const EditHome = () => {
  let navigate = useNavigate();
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <Title>Editar el Home</Title>
          <Button
            onClick={() => navigate("/admin-page", { replace: true })}
            variant="info"
            size="sm"
          >
            Volver al Administrador
          </Button>
          <ButtonsContainer>
            <a href="/list-carrousel">
              <ButtonEdit
                label="Editar Carousel"
                bgColor="#c2dc71"
                bgColorHover="#b2ec71"
              ></ButtonEdit>
            </a>
            {/*             <a href="/list-skills">
              <ButtonEdit
                label="Editar Skills"
                bgColor="#9ed7c8"
                bgColorHover="#5ed7c8"
              ></ButtonEdit>
            </a> */}
            <a href="/edit-wellcome">
              <ButtonEdit
                label="Editar Wellcome"
                bgColor="#5ed7c8"
                bgColorHover="#f5ab"
              ></ButtonEdit>
            </a>
            <a href="/list-team">
              <ButtonEdit
                label="Editar Team"
                bgColor="#b3d4fc"
                bgColorHover="#c9d4fc"
              ></ButtonEdit>
            </a>
            <a href="/list-Info">
              <ButtonEdit
                label="Editar Informacion"
                bgColor="#f5abcb"
                bgColorHover="#f5ab"
              ></ButtonEdit>
            </a>
            <a href="/gallery-menu">
              <ButtonEdit
                label="Editar Galeria"
                bgColor="#c2dc71"
                bgColorHover="#b2ec71"
              ></ButtonEdit>
            </a>
          </ButtonsContainer>
        </div>
      </div>
    </div>
  );
};

export default EditHome;

const Title = styled.h1`
  margin: 60px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
`;
