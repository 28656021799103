import React, { useEffect, useState } from "react";
import {
  getDataSectionWellcome,
  getGalleryList,
  getGalleryVideosList,
  getInfoKidsList,
  getInfoParentsList,
  getListDataSectionTeam,
  getListSectionIntro,
  getListSkills,
} from "../services/home.services";

import SectionContact from "../components/SectionPages/SectionContact";
import SectionGalery from "../components/SectionPages/SectionGalery";
import SectionInfo from "../components/SectionPages/SectionInfo";
import SectionIntro from "../components/SectionPages/SectionIntro";
import SectionSkills from "../components/SectionPages/SectionSkills";
import SectionTeam from "../components/SectionPages/SectionTeam";
import SectionTestimonials from "../components/SectionPages/SectionTestimonials";
import SectionWellcome from "../components/SectionPages/SectionWellcome";

const Home = () => {
  const [dataSectionWellcome, setDataSectionWellcome] = useState(null);
  const [dataIntro, setDataIntro] = useState(null);
  const [dataTeam, setDataTeam] = useState(null);
  const [dataSkill, setDataSkill] = useState(null);
  const [dataKids, setDataKids] = useState(null);
  const [dataParnts, setDataParnts] = useState(null);
  const [dataGallery, setDataGallery] = useState(null);
  const [dataGalleryVideos, setDataGalleryVideos] = useState(null);

  useEffect(() => {
    getInfoKidsList().then((response) => {
      setDataKids(response);
    });
    getInfoParentsList().then((response) => {
      setDataParnts(response);
    });
    getGalleryVideosList().then((response) => {
      setDataGalleryVideos(response);
    });
    getGalleryList().then((response) => {
      setDataGallery(response);
    });
    getListDataSectionTeam().then((response) => {
      setDataTeam(response);
    });
    // getListSkills().then((response) => {
    //   setDataSkill(response.data);
    // });
    getListSectionIntro().then((response) => {
      setDataIntro(response);
    });
    getDataSectionWellcome().then((response) => {
      setDataSectionWellcome(response);
    });
  }, []);

  return (
    <div>
      <main className="page-content">
        <SectionIntro dataIntro={dataIntro} />
        {/* <SectionSkills dataSkills={dataSkill} /> */}
        <SectionWellcome dataSectionWellcome={dataSectionWellcome} />
        <SectionTeam dataTeam={dataTeam} />
        <SectionInfo dataInfoKids={dataKids} dataInfoParents={dataParnts} />
        <SectionGalery
          dataGallery={dataGallery}
          dataGalleryVideo={dataGalleryVideos}
        />
        {/* <SectionTestimonials /> */}
      </main>
    </div>
  );
};

export default Home;
