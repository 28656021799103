import React from "react";
import styled from "styled-components";

const ButtonEdit = (props) => {
  const { label, bgColor, bgColorHover } = props;

  return (
    <Button bgColor={bgColor} bgColorHover={bgColorHover}>
      <strong>
        <Label>{label}</Label>
      </strong>
    </Button>
  );
};

export default ButtonEdit;

const Button = styled.button`
  height: 250px;
  width: 250px;
  margin: 30px;
  color: black;
  border-radius: 50px;
  background-color: ${(props) => props.bgColor};
  font-size: 22px;
  border: none;

  &:hover {
    background-color: ${(props) => props.bgColorHover};
    color: #fff;
    border: none;
  }
`;

const Label = styled.p`
  text-decoration: none;
`;
