import "firebase/auth";

import { getAuth, signOut } from "firebase/auth";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../context/UserContext";

const PageTopLine = () => {
  return (
    <>
      <div className="page_topline">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4 ">
              <div className="login-controls">
                <div className="dropdown"></div>
              </div>
            </div>
            <div className="col-sm-4 text-center second-column">
              <div className="widget widget_search"></div>
            </div>
            <div className="col-sm-4 text-right third-column">
              <div className="address">
                <i className="rt-icon2-location-outline"></i> Provo, Utah
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTopLine;
