import React, { useEffect } from "react";

import { useDataPages } from "../../context/DataHome";

const SectionInfo = (props) => {
  const { dataInfoKids, dataInfoParents } = props;
  const { lenguageSelected } = useDataPages();

  useEffect(() => {}, [dataInfoKids, dataInfoParents]);
  return (
    <section className="info">
      <div className="container">
        <div className="row">
          {dataInfoKids && (
            <div className="col-md-6 for-nannies">
              <p className="grand-hotel">
                {lenguageSelected === "es"
                  ? dataInfoKids[0].title_es
                  : dataInfoKids[0].title_en}
              </p>
              <h3 className="poppins">
                {lenguageSelected === "es"
                  ? dataInfoKids[0].sub_title_color_1_es
                  : dataInfoKids[0].sub_title_color_1_en}
                <span className="pink">
                  {" "}
                  {lenguageSelected === "es"
                    ? dataInfoKids[0].sub_title_color_2_es
                    : dataInfoKids[0].sub_title_color_2_en}
                </span>
              </h3>
              <ul>
                <li>
                  <div className="step-icon">
                    <i className="rt-icon2-edit"></i>
                  </div>
                  <div className="step-descr">
                    <h4>
                      {" "}
                      {lenguageSelected === "es"
                        ? "Registro de los niños"
                        : "Kid's Register"}
                    </h4>
                    <p>
                      {lenguageSelected === "es"
                        ? dataInfoKids[0].info_1_content_es
                        : dataInfoKids[0].info_1_content_en}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="step-icon">
                    <i className="rt-icon2-lightbulb3"></i>
                  </div>
                  <div className="step-descr">
                    <h4>
                      {" "}
                      {lenguageSelected === "es"
                        ? "Ideas a tener en cuenta Para los Niños"
                        : "Ideas to consider for kids"}
                    </h4>
                    <p>
                      {lenguageSelected === "es"
                        ? dataInfoKids[0].info_2_content_es
                        : dataInfoKids[0].info_2_content_en}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="step-icon">
                    <i className="rt-icon2-watch"></i>
                  </div>
                  <div className="step-descr">
                    <h4>
                      {" "}
                      {lenguageSelected === "es" ? "Horarios" : "Schedules"}
                    </h4>
                    <p>
                      {lenguageSelected === "es"
                        ? dataInfoKids[0].info_3_content_es
                        : dataInfoKids[0].info_3_content_en}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          )}
          {dataInfoParents && (
            <div className="col-md-6 for-parents">
              <p className="grand-hotel">
                {" "}
                {lenguageSelected === "es"
                  ? dataInfoParents[0].title_es
                  : dataInfoParents[0].title_en}
              </p>
              <h3 className="poppins">
                {lenguageSelected === "es"
                  ? dataInfoParents[0].sub_title_color_1_es
                  : dataInfoParents[0].sub_title_color_1_en}{" "}
                <span>
                  {" "}
                  {lenguageSelected === "es"
                    ? dataInfoParents[0].sub_title_color_2_es
                    : dataInfoParents[0].sub_title_color_2_en}{" "}
                </span>
              </h3>
              <ul>
                <li>
                  <div className="step-icon">
                    <i className="rt-icon2-group-outline"></i>
                  </div>
                  <div className="step-descr">
                    <h4>
                      {" "}
                      {lenguageSelected === "es"
                        ? "Información para los padres"
                        : "Information for parents"}{" "}
                    </h4>
                    <p>
                      {lenguageSelected === "es"
                        ? dataInfoParents[0].info_1_content_es
                        : dataInfoParents[0].info_1_content_en}{" "}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="step-icon">
                    <i className="rt-icon2-eye-outline"></i>
                  </div>
                  <div className="step-descr">
                    <h4>
                      {" "}
                      {lenguageSelected === "es"
                        ? "Ideas a tener en cuenta para los Padres"
                        : "Ideas to consider for parents"}
                    </h4>
                    <p>
                      {lenguageSelected === "es"
                        ? dataInfoParents[0].info_2_content_es
                        : dataInfoParents[0].info_2_content_en}{" "}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="step-icon">
                    <i className="rt-icon2-message"></i>
                  </div>
                  <div className="step-descr">
                    <h4>
                      {" "}
                      {lenguageSelected === "es" ? "Contactanos" : "Contact us"}
                    </h4>
                    <p>
                      {lenguageSelected === "es"
                        ? dataInfoParents[0].info_3_content_es
                        : dataInfoParents[0].info_3_content_en}{" "}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionInfo;
