import React from "react";

const SectionCopyRight = () => {
  return (
    <section className="page_copyright">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <p>
              &copy; Copyright 2022 by <a href="#">Gian Machella Design</a>. All
              Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionCopyRight;
