import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { getAbout } from "../services/home.services";
import styled from "styled-components";
import { useDataPages } from "../context/DataHome";
import { useNavigate } from "react-router-dom";

const About = () => {
  const [data, setData] = useState(null);
  const { lenguageSelected } = useDataPages();
  const apiUrl = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();

  useEffect(() => {
    getAbout("D8AE1S1HwBjYUfc14ljF").then((response) => {
      setData(response);
    });
  }, []);

  return (
    <Container>
      <TitleBlog>
        <h3 className="poppins">
          {lenguageSelected === "es" ? (
            <>
              <span className="pink">N</span>o
              <span className="light-green">s</span>
              <span className="turquoise">o</span>
              <span className="pink">t</span>
              <span className="turquoise">r</span>o
              <span className="light-green">s</span>
            </>
          ) : (
            <>
              <span className="pink">A</span>b
              <span className="light-green">o</span>
              <span className="turquoise">u</span>
              <span className="pink">t</span>
            </>
          )}
        </h3>
      </TitleBlog>
      {data && (
        <ContentCard>
          <ImageContainer>
            <Image src={data.image} alt="" />
          </ImageContainer>
          <TextContainer>
            <Title>
              {lenguageSelected === "es" ? data.title_es : data.title_en}
            </Title>
            <Description>
              {data.content_1_es !== "null" && data.content_1_en !== "null" && (
                <>
                  {lenguageSelected === "es"
                    ? data.content_1_es
                    : data.content_1_en}
                </>
              )}
            </Description>
            <Description>
              {data.content_2_es !== "null" && data.content_2_en !== "null" && (
                <>
                  {lenguageSelected === "es"
                    ? data.content_2_es
                    : data.content_2_en}
                </>
              )}
            </Description>
            <Description>
              {data.content_3_es !== "null" && data.content_3_en !== "null" && (
                <>
                  {lenguageSelected === "es"
                    ? data.content_3_es
                    : data.content_3_en}
                </>
              )}
            </Description>
            <Description>
              {data.content_4_es !== "null" && data.content_4_en !== "null" && (
                <>
                  {lenguageSelected === "es"
                    ? data.content_4_es
                    : data.content_4_en}
                </>
              )}
            </Description>
          </TextContainer>
        </ContentCard>
      )}
    </Container>
  );
};

export default About;

const ContentCard = styled.div`
  border: 1px solid #f5abcb;
  border-radius: 10px;
  box-shadow: 0px 5px 20px #f5abcb;
  margin: 40px auto;
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 50px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    display: flex;
    margin: 0px auto;
    padding: 20px;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const TextContainer = styled.div`
  width: 50%;
  padding: 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px;
  }
`;

const Description = styled.p`
  font-size: 15px;
  line-height: 20px;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 35px;
`;

const Image = styled.img`
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
`;

const TitleBlog = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
