import React, { useEffect } from "react";

import { useDataPages } from "../../context/DataHome.js";

const SectionWellcome = (props) => {
  const { dataSectionWellcome } = props;
  const { lenguageSelected } = useDataPages();

  useEffect(() => {}, [dataSectionWellcome]);
  return (
    <section className="welcome">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="grand-hotel">
              {lenguageSelected === "es" ? (
                <>{dataSectionWellcome ? dataSectionWellcome.title_es : ""}</>
              ) : (
                <>{dataSectionWellcome ? dataSectionWellcome.title_en : ""}</>
              )}
            </p>
            <h3 className="poppins">
              {lenguageSelected === "es" ? (
                <>
                  {dataSectionWellcome
                    ? dataSectionWellcome.title_color_1_es
                    : ""}
                </>
              ) : (
                <>
                  {dataSectionWellcome
                    ? dataSectionWellcome.title_color_1_en
                    : ""}
                </>
              )}
              <span className="pink">
                {lenguageSelected === "es" ? (
                  <>
                    {dataSectionWellcome
                      ? dataSectionWellcome.title_color_2_es
                      : ""}
                  </>
                ) : (
                  <>
                    {dataSectionWellcome
                      ? dataSectionWellcome.title_color_2_en
                      : ""}
                  </>
                )}
              </span>
            </h3>
            <p className="about-site">
              {lenguageSelected === "es" ? (
                <>
                  {dataSectionWellcome
                    ? dataSectionWellcome.description_es
                    : ""}
                </>
              ) : (
                <>
                  {dataSectionWellcome
                    ? dataSectionWellcome.description_en
                    : ""}
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWellcome;
