import React, { useEffect } from "react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";

import styled from "styled-components";
import { useDataPages } from "../../context/DataHome";
import { useNavigate } from "react-router-dom";

const EnrollChild = () => {
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);
  const { lenguageSelected } = useDataPages();
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  async function createUser(userData) {
    try {
      await createUserWithEmailAndPassword(
        auth,
        userData.parent_email,
        userData.password
      ).then((userFirebase) => {
        setShowAlertSuccess(true);
        const docuRef = doc(db, "users", userFirebase.user.uid);
        setDoc(docuRef, userData);
        navigate("/admin-page");
      });
    } catch (error) {
      setShowAlertError(true);
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();
    const userData = {
      parent_name: e.target.parent_name.value,
      parent_lastname: e.target.parent_last_name.value,
      parent_email: e.target.parent_email.value,
      parent_phone: e.target.parent_phone.value,
      address: e.target.address.value,
      child_name: e.target.child_name.value,
      child_last_name: e.target.child_last_name.value,
      password: `${
        e.target.child_name.value.toLowerCase() +
        e.target.child_last_name.value.toLowerCase()
      }`,
      role: "user",
    };

    createUser(userData);
  };

  return (
    <Container>
      <TitleBlog>
        <h3 className="poppins">
          {lenguageSelected === "es" ? (
            <>
              <span className="pink">I</span>n
              <span className="light-green">s</span>
              <span className="turquoise">c</span>
              <span className="pink">r</span>
              <span className="turquoise">i</span>p
              <span className="light-green">c</span>
              <span className="pink">i</span>ó
              <span className="light-green">n</span>
            </>
          ) : (
            <>
              <span className="pink">E</span>n
              <span className="light-green">r</span>
              <span className="turquoise">o</span>
              <span className="pink">l</span>l
            </>
          )}
        </h3>
      </TitleBlog>
      <ContentCard>
        <form onSubmit={submitHandler}>
          <Form>
            <div class="m-3 col-md-3">
              <label for="exampleFormControlInput1" class="form-label">
                Nombre de Mamá o Papá
              </label>
              <input
                type="text"
                class="form-control"
                id="parent_name"
                placeholder="Nombre"
              />
            </div>
            <div class="m-3 col-md-3">
              <label for="exampleFormControlInput1" class="form-label">
                Apellido de Mamá o Papá
              </label>
              <input
                type="text"
                class="form-control"
                id="parent_last_name"
                placeholder="Apellido"
              />
            </div>
            <div class="m-3 col-md-3">
              <label for="exampleFormControlInput1" class="form-label">
                Telefono de Mamá o Papá
              </label>
              <input
                type="text"
                class="form-control"
                id="parent_phone"
                placeholder="Número"
              />
            </div>
            <div class="m-3 col-md-9">
              <label for="exampleFormControlInput1" class="form-label">
                Dirección
              </label>
              <input
                type="text"
                class="form-control"
                id="address"
                placeholder="Dirección"
              />
            </div>
            <div class="m-3 col-md-3">
              <label for="exampleFormControlInput1" class="form-label">
                Email de Mamá o Papá
              </label>
              <input
                type="text"
                class="form-control"
                id="parent_email"
                placeholder="Email"
              />
            </div>
            <div class="m-3 col-md-3">
              <label for="exampleFormControlInput1" class="form-label">
                Nombre del niño(a)
              </label>
              <input
                type="text"
                class="form-control"
                id="child_name"
                placeholder="Nombre"
              />
            </div>
            <div class="m-3 col-md-3">
              <label for="exampleFormControlInput1" class="form-label">
                Apellido del niño(a)
              </label>
              <input
                type="text"
                class="form-control"
                id="child_last_name"
                placeholder="Apellido"
              />
            </div>
            <a href="/admin-page">
              <button type="button" class="btn btn-light col-md-4 m-5">
                Cancelar
              </button>
            </a>
            <button type="submit" class="btn btn-success col-md-4 m-5">
              Guardar
            </button>
            {showAlertSuccess && (
              <div class="alert alert-success col-md-10 m-10" role="alert">
                Se registro el niño(a) con exito
              </div>
            )}
            {showAlertError && (
              <div class="alert alert-danger col-md-10 m-10" role="alert">
                Hubo un error al tratar de registrar el niño(a)
              </div>
            )}
          </Form>
        </form>
      </ContentCard>
    </Container>
  );
};

export default EnrollChild;

const ContentCard = styled.div`
  border: 1px solid #f5abcb;
  border-radius: 10px;
  box-shadow: 0px 5px 20px #f5abcb;
  margin: 40px auto;
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 50px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    display: flex;
    margin: 0px auto;
    padding: 20px;
  }
`;

const Container = styled.div`
  padding: 20px;
`;

const Form = styled.div`
  align-items: center;
`;

const TitleBlog = styled.div`
  width: 100%;
  heigeht: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
