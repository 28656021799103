import React from "react";
import logo from "../../assets/logo.png";
import { useDataPages } from "../../context/DataHome";

const Footer = () => {
  const { lenguageSelected } = useDataPages();
  return (
    <footer className="page_footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12"></div>
        </div>
        <div className="row">
          <div className="col-md-4 text-center">
            <div className="open-hours">
              <h3>
                {lenguageSelected === "es" ? "Horario" : "Open"}{" "}
                <span>
                  {lenguageSelected === "es" ? "Funcionamiento" : "Hours"}
                </span>
              </h3>
              <div>
                {lenguageSelected === "es"
                  ? "Lunes - Viernes"
                  : "Monday - Friday"}{" "}
                <span>6:00 a.m. - 04:30 p.m.</span>
              </div>
              <div>
                {lenguageSelected === "es" ? "Sabado" : "Saturday"}{" "}
                <span>{lenguageSelected === "es" ? "Cerrado" : "Closed"} </span>
              </div>
              <div>
                {lenguageSelected === "es" ? "Domingo" : "Sunday"}{" "}
                <span>{lenguageSelected === "es" ? "Cerrado" : "Closed"} </span>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-center">
            <div className="widget widget_mailchimp">
              <h3 className="widget-title">ABY Daycare</h3>

              <p>
                {lenguageSelected === "es"
                  ? "Programa de atención y estimulación temprana para niños de 6 meses - 5 años atendiendo sus individualidades, desarrollo evolutivo e intereses y necesidades según su edad."
                  : "Early care and stimulation program for children 6 months - 5 years old attending to their individualities, evolutionary development and interests and needs according to their age."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <img src={logo} alt="logo-footer" className="logo-footer" />
    </footer>
  );
};

export default Footer;
