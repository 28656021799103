import "../styles/login.styles.css";
import "firebase/auth";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import BrowserStorage from "../helpers/BrowserStorage";
import { getUserById } from "../services/home.services";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../context/UserContext";

const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const { SetUserData } = useUserData();
  let navigate = useNavigate();

  const onSubmit = (form, event) => {
    event.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        SetUserData(user);
        BrowserStorage.setLocalStorage("user", userCredential.user);
        getUserById(userCredential.user.uid).then((response) => {
          console.log(response);
          BrowserStorage.setLocalStorage("userData", response);
          navigate(
            response.role === "admin" ? `/admin-page` : "/comunication",
            { replace: true }
          );
        });
        // ...
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <section className="page_breadcrumbs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="wrapper">
              <div className="text-center mt-5 name">Incia Seción</div>
              <form onSubmit={handleSubmit(onSubmit)} className="p-3 mt-3">
                <div className="form-field d-flex align-items-center">
                  <input
                    {...register("email", { required: { value: true } })}
                    type="email"
                    name="email"
                    id="userName"
                    placeholder="Username (Email)"
                  />
                </div>
                {errors.email && (
                  <p className="text-danger">El e-mail es requerido</p>
                )}
                <div className="form-field d-flex justify-content-between">
                  <ButtonPasswordContainer>
                    <input
                      {...register("password", { required: { value: true } })}
                      type={!showPassword ? "password" : "text"}
                      name="password"
                      id="pwd"
                      placeholder="Password"
                    />
                    <ButtonPassword>
                      {showPassword ? (
                        <FaEye
                          onClick={() => setShowPassword(!showPassword)}
                        ></FaEye>
                      ) : (
                        <FaEyeSlash
                          onClick={() => setShowPassword(!showPassword)}
                        ></FaEyeSlash>
                      )}
                    </ButtonPassword>
                  </ButtonPasswordContainer>
                </div>
                {errors.password && (
                  <p className="text-danger">La Contraseña es requerida</p>
                )}
                {error && (
                  <p className="text-danger">Usuario o contraseña invalido</p>
                )}
                <button type="submit" className="btn mt-3">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

const ButtonPasswordContainer = styled.div`
  display: flex;
`;

const ButtonPassword = styled.div`
  margin: 10px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;
