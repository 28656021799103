import { Button } from "react-bootstrap";
import React from "react";

const SectionContact = () => {
  return (
    <section className="contacts">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-4">
            <span className="rt-icon2-location-outline pink"></span>
            <p>Provo, Utah</p>
          </div>
          <div className="col-md-4">
            <span className="rt-icon2-mail2 blue"></span>
            <a href="google.com"> info@abydaycare.com </a>
          </div>
          <div className="col-md-4">
            <span className="rt-icon2-phone-outline light-green"></span>
            <p>+1 (385) 236 7056</p>
          </div>
        </div>
      </div>
      <div className="container-fluid parallax">
        <div className="row">
          <div className="col-xs-12">
            <div className="form-wrap">
              <form className="contact-form" method="post" action="/">
                <input
                  type="text"
                  aria-required="true"
                  size="30"
                  name="name"
                  id="name"
                  className="form-control"
                  placeholder="Full Name"
                />
                <input
                  type="email"
                  aria-required="true"
                  size="30"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Email Adress"
                />
                <input
                  type="text"
                  aria-required="true"
                  size="30"
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder="Phone Number"
                />
                <div className="form-control-group">
                  <input
                    type="text"
                    aria-required="true"
                    className="form-control"
                    name="date"
                    id="datepicker"
                    placeholder="Date"
                  />
                </div>
                <textarea
                  rows="6"
                  cols="45"
                  name="message"
                  id="message"
                  placeholder="Additional Info"
                ></textarea>
                <div>
                  <Button variant="success">Send Message</Button>{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionContact;
