import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import { FaArrowLeft } from "react-icons/fa";
import InputAdmin from "../../../components/Input/Input";
import React from "react";
import { createSkill } from "../../../services/home.services";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CreateSkill = () => {
  const methods = useForm();
  let navigate = useNavigate();

  const onSubmit = (data) => {
    createSkill(data).then((response) => {
      //navigate("/list-carrousel", { replace: true });
    });
  };

  const cancel = () => {
    navigate("/list-carrousel", { replace: true });
  };
  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Crear Skill</Title>
          <FormContainer className="row">
            <div className="col-md-12">
              <Button
                onClick={() => navigate("/list-skills", { replace: true })}
                variant="primary"
                size="sm"
              >
                <FaArrowLeft />
              </Button>{" "}
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Sub-Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`sub_title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Sub-Titulo Español"}
                fsLabel={"2rem"}
                inputName={`sub_title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Skill 1 Ingles"}
                fsLabel={"2rem"}
                inputName={"skill_1_en"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Skill 1 Español"}
                fsLabel={"2rem"}
                inputName={"skill_1_es"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Skill 2 Ingles"}
                fsLabel={"2rem"}
                inputName={"skill_2_en"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Skill 2 Español"}
                fsLabel={"2rem"}
                inputName={"skill_2_es"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Skill 3 Ingles"}
                fsLabel={"2rem"}
                inputName={"skill_3_en"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Skill 3 Español"}
                fsLabel={"2rem"}
                inputName={"skill_3_es"}
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default CreateSkill;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
