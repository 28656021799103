import React from "react";
import styled from "styled-components";
import { useDataPages } from "../../context/DataHome.js";
import { useEffect } from "react";

const SectionTeam = (props) => {
  const { dataTeam } = props;
  const { lenguageSelected } = useDataPages();

  console.log(dataTeam);

  useEffect(() => {}, [dataTeam]);

  return (
    <section className="team">
      <div className="flexslider">
        {dataTeam && (
          <>
            {dataTeam.map((item, index) => (
              <TemContainer key={index}>
                <Image src={item.image} alt="TeamMember" />
                <ContainerTex>
                  <p className="grand-hotel">{item.name}</p>
                  <h3 className="poppins text-info">
                    {lenguageSelected === "es" ? item.title_es : item.title_en}
                  </h3>
                  <p className="nanny-descr">
                    {lenguageSelected === "es"
                      ? item.description_es
                      : item.description_en}
                  </p>
                </ContainerTex>
              </TemContainer>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default SectionTeam;

const Image = styled.img`
  width: 500px;
`;

const ContainerTex = styled.div`
  padding: 60px;
`;

const TemContainer = styled.div`
  min-height: 400px;
  margin: 30px;
  padding: 50px;
  display: flex;
  background-color: #ffff;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 600px) {
    display: grid;
  }
`;
