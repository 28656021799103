import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { getBlogArticlesList } from "../services/home.services";
import styled from "styled-components";
import { useDataPages } from "../context/DataHome";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const { lenguageSelected } = useDataPages();
  const [data, setData] = useState(null);
  let navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getBlogArticlesList().then((response) => {
      setData(response);
    });
  }, []);

  return (
    <>
      <TitleBlog>
        {lenguageSelected === "es" ? (
          <h3 className="poppins">
            <span className="pink">B</span>l
            <span className="light-green">o</span>
            <span className="turquoise">g </span>
            <span className="pink">G</span>a
            <span className="light-green">l</span>
            <span className="turquoise">e</span>
            <span className="pink">r</span>
            <span className="light-green">i</span>
            <span className="turquoise">a</span>
          </h3>
        ) : (
          <h3 className="poppins">
            <span className="pink">B</span>l
            <span className="light-green">o</span>
            <span className="turquoise">g </span>
            <span className="pink">G</span>a
            <span className="light-green">l</span>
            <span className="turquoise">l</span>
            <span className="pink">e</span>r
            <span className="light-green">i</span>
            <span className="turquoise">e</span>
            <span className="pink">s</span>
          </h3>
        )}
      </TitleBlog>
      {data && (
        <BlogsCards>
          {data.map((item, index) => (
            <ContentCard key={index}>
              <ImageContainer>
                <Image src={item.image} alt="abydaycare-aticle-blog" />
              </ImageContainer>
              <TextContainer>
                <h2>
                  {lenguageSelected === "es" ? item.title_es : item.title_en}
                </h2>
                <Description>
                  {lenguageSelected === "es"
                    ? item.sub_title_card_es
                    : item.sub_title_card_en}
                </Description>
                <p>{item.autor}</p>
                <Button
                  onClick={() =>
                    navigate(`/blog/${item.id}`, { replace: true })
                  }
                  variant="success"
                >
                  {lenguageSelected === "es" ? "Ver más" : "See more"}
                </Button>{" "}
              </TextContainer>
            </ContentCard>
          ))}
        </BlogsCards>
      )}
    </>
  );
};

export default Blog;

const ContentCard = styled.div`
  width: 600px;
  height: 300px;
  border: 2px solid #9ed7c8;
  border-radius: 10px;
  box-shadow: 0px 5px 20px #9ed7c8;
  display: flex;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  margin: 20px;
  @media only screen and (max-width: 600px) {
    width: 400px;
    height: 500px;
    flex-direction: column;
    overflow: hidden;
  }
`;

const ImageContainer = styled.div`
  width: 300px;
`;

const Image = styled.img`
  width: 300px;
  @media only screen and (max-width: 600px) {
    width: 200px;
  }
`;

const TextContainer = styled.div`
  width: 300px;
  height: 300px;
  padding: 20px;
`;

const Description = styled.div`
  font-size: 10px;
`;

const TitleBlog = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const BlogsCards = styled.div`
  display: grid;
  grid-template-columns: 650px 650px;
  justify-content: center;
  margin-top: 30px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
`;
