import React, { useState } from "react";

import ButtonEdit from "../../components/ButtonEdit/ButtonEdit.jsx";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const AdminPage = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <Title>Administrativo</Title>
          <a href="/child-list">
            <ButtonEdit
              label="Enroll Child"
              bgColor="#9ed7c8"
              bgColorHover="#5ed7c8"
            ></ButtonEdit>
          </a>
          <a href="/notes-list">
            <ButtonEdit
              label="Crear Nota"
              bgColor="#f5abcb"
              bgColorHover="#f5ab"
            ></ButtonEdit>
          </a>
          <Title>Editor de Página</Title>
          <ButtonsContainer>
            <a href="/">
              <ButtonEdit
                label="Editar Home"
                bgColor="#f5abcb"
                bgColorHover="#f5ab"
              ></ButtonEdit>
            </a>
            <a href="/edit-about">
              <ButtonEdit
                label="Editar About"
                bgColor="#b3d4fc"
                bgColorHover="#c9d4fc"
              ></ButtonEdit>
            </a>
            <a href="edit-services">
              <ButtonEdit
                label="Editar Servicios"
                bgColor="#c2dc71"
                bgColorHover="#b2ec71"
              ></ButtonEdit>
            </a>
            <a href="/list-blog">
              <ButtonEdit
                label="Editar Blog"
                bgColor="#9ed7c8"
                bgColorHover="#5ed7c8"
              ></ButtonEdit>
            </a>
          </ButtonsContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;

const Title = styled.h1`
  margin-top: 120px;
  margin-bottom: 60px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
`;
