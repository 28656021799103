import { FaArrowLeft, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  getInfoKidsList,
  getInfoParentsList,
} from "../../../services/home.services";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ListInfo = () => {
  const [dataKids, setDataKids] = useState(null);
  const [dataParents, setDataParents] = useState(null);
  const [refresh, setRefresh] = useState(null);
  let navigate = useNavigate();

  const handleEditKids = (id) => {
    navigate(`/edit-info-kids/${id}`, { replace: true });
  };

  const handleEditParents = (id) => {
    navigate(`/edit-info-parents/${id}`, { replace: true });
  };

  useEffect(() => {
    getInfoKidsList().then((response) => {
      setDataKids(response);
      console.log(response);
    });
    getInfoParentsList().then((response) => {
      setDataParents(response);
      console.log(response);
    });
  }, [refresh]);

  return (
    <TableContainer>
      <Title>
        <h2>Listado de Informacion</h2>
      </Title>
      <div className="m-1 col-md-12">
        <ButtonAdd>
          <Button
            onClick={() => navigate("/edit-home", { replace: true })}
            variant="info"
            size="sm"
          >
            <FaArrowLeft />
          </Button>{" "}
        </ButtonAdd>
      </div>
      <Title>
        <h4>Informacion Niños</h4>
      </Title>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Subtitulo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {dataKids && (
            <>
              {dataKids.map((item, index) => (
                <tr key={index}>
                  <td>{item.title_es}</td>
                  <td>
                    {item.sub_title_color_1_es +
                      " " +
                      item.sub_title_color_2_es}
                  </td>
                  <td>
                    <ActionsContainer>
                      <ButtonContainer>
                        <Button
                          onClick={() => handleEditKids(item.id)}
                          className="m-3"
                          variant="primary"
                          size="sm"
                        >
                          <FaEdit />
                        </Button>
                      </ButtonContainer>
                    </ActionsContainer>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
      <Title>
        <h4>Informacion Padres</h4>
      </Title>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Subtitulo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {dataParents && (
            <>
              {dataParents.map((item, index) => (
                <tr key={index}>
                  <td>{item.title_es}</td>
                  <td>
                    {item.sub_title_color_1_es +
                      " " +
                      item.sub_title_color_2_es}
                  </td>
                  <td>
                    <ActionsContainer>
                      <ButtonContainer>
                        <Button
                          onClick={() => handleEditParents(item.id)}
                          className="m-3"
                          variant="primary"
                          size="sm"
                        >
                          <FaEdit />
                        </Button>
                      </ButtonContainer>
                    </ActionsContainer>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default ListInfo;

const TableContainer = styled.div`
  padding: 50px 300px;
`;
const ActionsContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
`;

const ButtonAdd = styled.div`
  margin: 20px;
`;

const Title = styled.div`
  margin: 60px 0px 15px 0px;
`;
