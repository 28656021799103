/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";

const DataPagesContext = React.createContext();

export function DataPagesProvider(props) {
  const [lenguageSelected, setLenguageSelected] = useState("es");

  async function SetLenguage(lenguage) {
    setLenguageSelected(lenguage);
  }

  const value = useMemo(() => {
    return {
      SetLenguage,
      lenguageSelected,
    };
  }, [lenguageSelected]);

  return <DataPagesContext.Provider value={value} {...props} />;
}

export function useDataPages() {
  const context = React.useContext(DataPagesContext);
  if (!context) {
    throw new Error("useDataPages must be inside the user provider");
  }

  return context;
}
