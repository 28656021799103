/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";

const UserContext = React.createContext();

export function UserProvider(props) {
  const [dataUser, setDataUser] = useState(localStorage.getItem("user"));
  const [isLogin, setIsLogin] = useState(localStorage.getItem("isLogin"));

  async function SetUserData(user) {
    setDataUser(user);
    setIsLogin(true);
    localStorage.setItem("user", user);
    localStorage.setItem("isLogin", true);
  }

  const value = useMemo(() => {
    return {
      SetUserData,
      setIsLogin,
      dataUser,
      isLogin,
    };
  }, [dataUser, isLogin]);

  return <UserContext.Provider value={value} {...props} />;
}

export function useUserData() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useDataPages must be inside the user provider");
  }

  return context;
}
