import { FaLock, FaLockOpen } from "react-icons/fa";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";

import BrowserStorage from "../../helpers/BrowserStorage";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import en from "../../assets/en.png";
import es from "../../assets/es.png";
import { get } from "firebase/database";
import logo from "../../assets/logo.png";
import styled from "styled-components";
import { useDataPages } from "../../context/DataHome.js";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../context/UserContext";

const Header = () => {
  const [userData, setUserData] = useState([]);
  const [lenguage, setLenguage] = useState("es");
  const { isLogin } = useUserData();
  const { SetLenguage, lenguageSelected } = useDataPages();
  let navigate = useNavigate();
  let expand = "md";

  const logOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        BrowserStorage.removeLocalStorage("user");
        BrowserStorage.removeLocalStorage("userData");
        BrowserStorage.removeLocalStorage("isLogin");
        navigate(`/`, { replace: true });
        window.location.reload(false);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  useEffect(() => {
    SetLenguage(lenguage);
    setUserData(BrowserStorage.getLocalStorage("userData"));
  }, [lenguage]);

  return (
    <header className="page_header header_white">
      <div className="header-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">
              <a href="/">
                <img src={logo} alt="Babysitter logo" className="top-logo" />
              </a>
            </div>
            <div className="col-sm-2 text-center">
              <div className="social-icons">
                <a
                  href="facebook.com"
                  className="social-icon color-bg-icon rounded-icon soc-facebook"
                >
                  {" "}
                </a>
                <a
                  href="instagram.com"
                  className="social-icon color-bg-icon rounded-icon soc-instagram"
                >
                  {" "}
                </a>
                <a
                  href="youtube.com"
                  className="social-icon color-bg-icon rounded-icon rt-icon2-youtube5"
                >
                  {" "}
                </a>
              </div>
            </div>
            <div className="col-sm-2 text-center">
              <div className="lenguage-icons">
                <div
                  onClick={() => setLenguage("es")}
                  className="social-icon color-bg-icon rounded-icon soc-spanish"
                >
                  <img src={es} width="20" alt="" />
                </div>
                <div
                  onClick={() => setLenguage("en")}
                  className="social-icon color-bg-icon rounded-icon soc-english"
                >
                  <img src={en} width="20" alt="" />{" "}
                </div>
                <div className="selected" />
              </div>
            </div>
            <div className="col-sm-4 text-right ">
              <div className="contact-info">
                <div className="contact-number">
                  <span>+1 385</span> 236 7056
                </div>
                <div className="work-hours">6:00 a.m. - 4:30 p.m.</div>
                {isLogin ? (
                  <>
                    <a className="" onClick={logOut}>
                      <FaLock />
                      {` Cerrar Sesión`}
                    </a>
                    <p>{`Hola ${userData ? userData.user_name : ""}`}</p>
                  </>
                ) : (
                  <a className="" href="/login">
                    <FaLockOpen />
                    {` Login`}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 text-center">
            <nav className="mainmenu_wrapper">
              <ul className="mainmenu sf-menu">
                <li className="active">
                  <a href="/">
                    {lenguageSelected === "es" ? "Inicio" : "Home"}
                  </a>
                </li>

                <li>
                  <a href="/about">
                    {lenguageSelected === "es" ? "Nosotros" : "About"}
                  </a>
                </li>
                <li>
                  <a href="/services">
                    {lenguageSelected === "es" ? "Servicios" : "Services"}
                  </a>
                </li>

                <li>
                  <a href="/blog">Blog</a>
                </li>

                <li>
                  <a href="/gallery">
                    {lenguageSelected === "es" ? "Galeria" : "Gallery"}
                  </a>
                </li>
                {isLogin && (
                  <li>
                    <a href="/comunication">
                      {lenguageSelected === "es"
                        ? "Communicación"
                        : "Communication"}
                    </a>
                  </li>
                )}
                {isLogin && userData && userData.role === "admin" && (
                  <li>
                    <a href="/admin-page">
                      {lenguageSelected === "es" ? "Administrador" : "Admin"}
                    </a>
                  </li>
                )}
              </ul>
            </nav>
            <MenuMobile>
              <div
                className="toggle_menu-wrap"
                data-spy="affix"
                data-offset-top="425"
              >
                <Navbar bg="light" expand={expand} className="mb-3">
                  <Container fluid>
                    <TitleMenuMobile>
                      <Navbar.Brand href="#">
                        <h4 className="poppins">
                          <span className="pink">A</span>B
                          <span className="light-green">Y</span>
                          <span className="turquoise"> </span>
                          <span className="pink">D</span>a
                          <span className="light-green">y</span>
                          <span className="turquoise">c</span>
                          <span className="pink">a</span>
                          <span className="light-green">r</span>e
                        </h4>
                      </Navbar.Brand>
                    </TitleMenuMobile>
                    <Navbar.Toggle
                      aria-controls={`offcanvasNavbar-expand-${expand}`}
                    />
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                    >
                      <Offcanvas.Header closeButton></Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 p-3">
                          <Nav.Link href="/">
                            {lenguageSelected === "es" ? "Inicio" : "Home"}
                          </Nav.Link>
                          <Nav.Link href="/about">
                            {lenguageSelected === "es" ? "Nosotros" : "About"}
                          </Nav.Link>
                          <Nav.Link href="/services">
                            {lenguageSelected === "es"
                              ? "Servicios"
                              : "Services"}
                          </Nav.Link>
                          <Nav.Link href="/blog">Blog</Nav.Link>
                          <Nav.Link href="/gallery">
                            {lenguageSelected === "es" ? "Galeria" : "Gallery"}
                          </Nav.Link>
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              </div>
            </MenuMobile>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

const TitleMenuMobile = styled.div`
  margin-top: -50px;
  padding: 10px;
`;

const MenuMobile = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;
