import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import {
  galleryUpdateItem,
  getGalleryItem,
} from "../../../services/home.services";

import { FaArrowLeft } from "react-icons/fa";
import InputAdmin from "../../../components/Input/Input";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditGalleryVideo = (props) => {
  const methods = useForm();
  const reset = methods.reset;
  let navigate = useNavigate();
  const { id } = useParams();

  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("id", data.id);
    formdata.append("title_es", data.title_es);
    formdata.append("title_en", data.title_en);
    formdata.append("image", data.image[0]);
    galleryUpdateItem(formdata, id).then((response) => {
      navigate("/list-gallery", { replace: true });
    });
  };

  const cancel = () => {
    navigate("/list-gallery", { replace: true });
  };

  useEffect(() => {
    getGalleryItem(id).then((response) => {
      let data = response;
      reset({
        id: data.id,
        title_es: data.title_es,
        title_en: data.title_en,
        image: data.image,
      });
    });
  }, [reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Editar "Team Section"</Title>
          <FormContainer className="row">
            <div className="col-md-12">
              <Button
                onClick={() => navigate("/list-gallery", { replace: true })}
                variant="primary"
                size="sm"
              >
                <FaArrowLeft />
              </Button>{" "}
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Foto"}
                fsLabel={"2rem"}
                inputName={`image`}
                type="file"
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default EditGalleryVideo;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
