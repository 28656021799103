import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";

import About from "../pages/About.jsx";
import AdminPage from "../pages/Admin/AdminPage.jsx";
import Blog from "../pages/Blog.jsx";
import BlogArticle from "../pages/BlogArticle.jsx";
import BrowserStorage from "../helpers/BrowserStorage.js";
import CarouselList from "../pages/Admin/EditHome/CarouselList.jsx";
import ChildList from "../pages/Admin/ChildList.jsx";
import { Communication } from "../pages/Communication.jsx";
import Contact from "../pages/Contact.jsx";
import Create from "../pages/Admin/EditAbout/Create.jsx";
import CreateBlogArticle from "../pages/Admin/EditBlog/CreateBlogArticle.jsx";
import CreateCarrouselItem from "../pages/Admin/EditHome/CreateCarrouselItem.jsx";
import CreateGalery from "../pages/Admin/EditHome/CreateGalery.jsx";
import CreateSkill from "../pages/Admin/EditHome/CreateSkill.jsx";
import CreateTeamMember from "../pages/Admin/EditHome/CreateTeamMember.jsx";
import CreateVideoGallery from "../pages/Admin/EditHome/CreateVideoGallery.jsx";
import EditAbout from "../pages/Admin/EditAbout/EditAbout.jsx";
import EditBlogArticle from "../pages/Admin/EditBlog/EditBlog.jsx";
import EditCarrousel from "../pages/Admin/EditHome/EditCarrousel.jsx";
import EditGallery from "../pages/Admin/EditHome/EditGallery.jsx";
import EditGalleryMenu from "../pages/Admin/EditGalleryMenu.jsx";
import EditHome from "../pages/Admin/EdtiHome.jsx";
import EditInfoKids from "../pages/Admin/EditHome/EditInfoKids.jsx";
import EditInfoParents from "../pages/Admin/EditHome/EditInfoParents.jsx";
import EditService from "../pages/Admin/EditServices/EditServices.jsx";
import EditSkill from "../pages/Admin/EditHome/EditSkill.jsx";
import EditTeam from "../pages/Admin/EditHome/EditTeam.jsx";
import EditWellcome from "../pages/Admin/EditHome/EditWellcome.jsx";
import EnrollChild from "../pages/Admin/EnrollChild.jsx";
import Gallery from "../pages/Gallery.jsx";
import Home from "../pages/Home";
import Layout from "../pages/Layout.jsx";
import ListBlog from "../pages/Admin/EditBlog/BlogList.jsx";
import ListGallery from "../pages/Admin/EditHome/ListGallery.jsx";
import ListInfo from "../pages/Admin/EditHome/ListInfo.jsx";
import ListSkills from "../pages/Admin/EditHome/ListSkills.jsx";
import ListVideoGallery from "../pages/Admin/EditHome/ListVideoGallery.jsx";
import Login from "../pages/Login.jsx";
import NotesList from "../pages/Admin/NotesList.jsx";
import Services from "../pages/Services.jsx";
import TeamList from "../pages/Admin/EditHome/TeamList.jsx";
import { getUserById } from "../services/home.services.js";
import { useUserData } from "../context/UserContext.js";

const RoutesApp = () => {
  const [user, setUser] = React.useState(null);
  const [userData, setUserData] = React.useState(null);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  });

  useEffect(() => {
    if (user) {
      let userData = BrowserStorage.getLocalStorage("user");
      getUserById(userData.uid).then((response) => {
        BrowserStorage.setLocalStorage("userData", response);
        setUserData(response);
      });
    }
  }, [user]);

  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/create" element={!user ? <Login /> : <Create />} />
          <Route path="/blog/:id" element={<BlogArticle />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/edit-about"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditAbout />
              )
            }
          />
          <Route
            path="/edit-services"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditService />
              )
            }
          />
          <Route
            path="/create-blog-article"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CreateBlogArticle />
              )
            }
          />
          <Route
            path="/edit-blog-article/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditBlogArticle />
              )
            }
          />
          <Route
            path="/admin-page"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <AdminPage />
              )
            }
          />
          <Route
            path="/edit-wellcome"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditWellcome />
              )
            }
          />
          <Route path="/edit-home" element={!user ? <Login /> : <EditHome />} />
          <Route
            path="/edit-skill/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditSkill />
              )
            }
          />
          <Route
            path="/edit-about"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditAbout />
              )
            }
          />
          <Route
            path="/edit-gallery-item/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditGallery />
              )
            }
          />
          <Route
            path="/edit-info-kids/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditInfoKids />
              )
            }
          />
          <Route
            path="/edit-info-parents/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditInfoParents />
              )
            }
          />
          <Route
            path="/edit-team/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditTeam />
              )
            }
          />
          <Route
            path="/edit-carrousel/:id"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditCarrousel />
              )
            }
          />
          <Route path="/list-team" element={!user ? <Login /> : <TeamList />} />
          <Route
            path="/list-skills"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <ListSkills />
              )
            }
          />
          <Route
            path="/list-carrousel"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CarouselList />
              )
            }
          />
          <Route
            path="/list-gallery"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <ListGallery />
              )
            }
          />
          <Route
            path="/list-info"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <ListInfo />
              )
            }
          />
          <Route
            path="/list-blog"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <ListBlog />
              )
            }
          />
          <Route
            path="/list-video-gallery"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <ListVideoGallery />
              )
            }
          />
          <Route
            path="/create-team-member"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CreateTeamMember />
              )
            }
          />
          <Route
            path="/create-skill"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CreateSkill />
              )
            }
          />
          <Route
            path="/add-gallery"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CreateGalery />
              )
            }
          />
          <Route
            path="/gallery-menu"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EditGalleryMenu />
              )
            }
          />
          <Route
            path="/add-video-gallery"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CreateVideoGallery />
              )
            }
          />
          <Route
            path="/create-carrousel-item"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <CreateCarrouselItem />
              )
            }
          />
          <Route
            path="/comunication"
            element={!user ? <Login /> : <Communication />}
          />
          <Route
            path="/enroll-child"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <EnrollChild />
              )
            }
          />
          <Route
            path="/child-list"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <ChildList />
              )
            }
          />
          <Route
            path="/notes-list"
            element={
              !user || (userData && userData.role === "user") ? (
                <Login />
              ) : (
                <NotesList />
              )
            }
          />
        </Routes>
      </Layout>
    </>
  );
};

export default RoutesApp;
