import {
  FaArrowLeft,
  FaEdit,
  FaPlus,
  FaStickyNote,
  FaTrashAlt,
} from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { createNote, getChildList } from "../../services/home.services";
import { doc, getFirestore, setDoc } from "firebase/firestore";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ChildList = () => {
  const [dataChildList, setDataChildList] = useState(null);
  const [show, setShow] = useState(false);
  const handleEdit = (id) => {};
  const [childName, setChildName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [childId, setChildId] = useState("");
  const [isReaded, setIsReaded] = useState(false);
  const db = getFirestore();
  let navigate = useNavigate();

  const handleRead = () => {
    setIsReaded(true);
  };

  const handleDelete = (id) => {};

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setDataChild = (data) => {
    setChildId(data.id);
    setChildName(data.child_name);
    setChildLastName(data.child_last_name);
    setParentName(data.parent_name);
    setParentLastName(data.parent_last_name);
  };

  const handleSubmit = (data) => {
    const dataNote = {
      title: document.getElementById("title").value,
      note: document.getElementById("note").value,
    };
    const docuRef = doc(db, "notes", childName + childLastName);
    setDoc(docuRef, dataNote);
    handleClose();
  };

  const goToEroll = () => {
    navigate(`/enroll-child`, { replace: true });
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <Button className="mb-3 col-md-2" variant="success" onClick={goToEroll}>
        Nueva Inscripción
      </Button>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Nombre del niño(a)</th>
            <th scope="col">Apellido del niño(a)</th>
            <th scope="col">Nombre del Representante</th>
          </tr>
        </thead>
        <tbody>
          {dataChildList?.map((item, key) => (
            <tr key={key}>
              <td>{item.child_name}</td>
              <td>{item.child_last_name}</td>
              <td>{item.parent_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Crear Nota para {childName} {childLastName}
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Título de la nota</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Ingresa el título"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nota</Form.Label>
              <Form.Control
                type="text"
                id="note"
                placeholder="Ingresa el contenido de la nota"
                as="textarea"
                rows={10}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="success" onClick={handleSubmit}>
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ChildList;

const Container = styled.div`
  margin: 100px;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
`;

const P = styled.p`
  font-weight: bold;
`;
