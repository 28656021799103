import axios from "axios";
import { db } from "../firebase";

const baseUrl = process.env.REACT_APP_API_URL;

export async function getChildList() {
  try {
    const query = db.collection("users");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      address: doc.data().address,
      child_last_name: doc.data().child_last_name,
      child_name: doc.data().child_name,
      parent_email: doc.data().parent_email,
      parent_lastname: doc.data().parent_lastname,
      parent_name: doc.data().parent_name,
      parent_phone: doc.data().parent_phone,
      password: doc.data().password,
      role: doc.data().role,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getUserById(id) {
  console.log(id);
  try {
    const query = db.collection("users").doc(id);
    const querySnapshot = await query.get();
    const docs = querySnapshot.data();

    const response = docs;
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getNotes() {
  try {
    const query = db.collection("notes");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      child_name: doc.data().child_name,
      title: doc.data().title,
      note: doc.data().note,
      readed: doc.data().readed,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getNotesById(id) {
  console.log(id);
  try {
    const query = db.collection("notes").doc(id);
    const querySnapshot = await query.get();
    const docs = querySnapshot.data();

    const response = docs;
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function createNote(data) {
  try {
    await db.collection("notes").doc().set(data);
  } catch (error) {
    return error;
  }
}

export async function updateNote(data, id) {
  try {
    const response = await db.collection("notes").doc(id).set(data);
    return response;
  } catch (error) {
    return error;
  }
}

// Wellcome services

export async function getDataSectionWellcome() {
  try {
    const query = db.collection("home-wellcome-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      title_color_1_en: doc.data().title_color_1_en,
      title_color_1_es: doc.data().title_color_1_es,
      title_color_2_en: doc.data().title_color_2_en,
      title_color_2_es: doc.data().title_color_2_es,
      description_en: doc.data().description_en,
      description_es: doc.data().description_es,
    }));
    return response[0];
  } catch (error) {
    return error;
  }
}

export async function updateDataHome(data, id) {
  try {
    const response = await db
      .collection("home-wellcome-section")
      .doc(id)
      .set(data);
    return response;
  } catch (error) {
    return error;
  }
}

// Carousel services

export async function createCarrouselItem(data) {
  try {
    await db.collection("home-carousel-section").doc().set(data);
  } catch (error) {
    return error;
  }
}

export async function getListSectionIntro() {
  try {
    const query = db.collection("home-carousel-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      title_color_1_en: doc.data().sub_title_color_1_en,
      title_color_1_es: doc.data().sub_title_color_1_es,
      title_color_2_en: doc.data().sub_title_color_2_en,
      title_color_2_es: doc.data().sub_title_color_2_es,
      image: doc.data().image,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getCarrouselById(id) {
  try {
    const query = await db.collection("home-carousel-section").doc(id);
    const querySnapshot = await query.get();
    const response = querySnapshot.data();
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateCarrouselItem(data, id) {
  try {
    const response = await db
      .collection("home-carousel-section")
      .doc(id)
      .set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteCarrouselItem(id) {
  try {
    const response = await db
      .collection("home-carousel-section")
      .doc(id)
      .delete();
    return response;
  } catch (error) {
    return error;
  }
}

// Team Services

export async function createDataTeam(data) {
  try {
    const response = await db.collection("home-team-section").doc().set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getListDataSectionTeam() {
  try {
    const query = db.collection("home-team-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      description_es: doc.data().description_es,
      description_en: doc.data().description_en,
      image: doc.data().image,
    }));
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getMemberTeam(id) {
  try {
    const query = await db.collection("home-team-section").doc(id);
    const querySnapshot = await query.get();
    const response = querySnapshot.data();
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateDataTeam(data, id) {
  try {
    const response = await db.collection("home-team-section").doc(id).set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteMemberTeam(id) {
  try {
    const response = await db.collection("home-team-section").doc(id).delete();
    return response;
  } catch (error) {
    return error;
  }
}

// Info Services

export async function getInfoKidsList() {
  try {
    const query = db.collection("home-info-kids-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      sub_title_color_1_es: doc.data().sub_title_color_1_es,
      sub_title_color_1_en: doc.data().sub_title_color_1_en,
      sub_title_color_2_es: doc.data().sub_title_color_2_es,
      sub_title_color_2_en: doc.data().sub_title_color_2_en,
      info_1_content_es: doc.data().info_1_content_es,
      info_1_content_en: doc.data().info_1_content_en,
      info_2_content_es: doc.data().info_2_content_es,
      info_2_content_en: doc.data().info_2_content_en,
      info_3_content_es: doc.data().info_3_content_es,
      info_3_content_en: doc.data().info_3_content_en,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInfoParentsList() {
  try {
    const query = db.collection("home-info-parents-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      sub_title_color_1_es: doc.data().sub_title_color_1_es,
      sub_title_color_1_en: doc.data().sub_title_color_1_en,
      sub_title_color_2_es: doc.data().sub_title_color_2_es,
      sub_title_color_2_en: doc.data().sub_title_color_2_en,
      info_1_content_es: doc.data().info_1_content_es,
      info_1_content_en: doc.data().info_1_content_en,
      info_2_content_es: doc.data().info_2_content_es,
      info_2_content_en: doc.data().info_2_content_en,
      info_3_content_es: doc.data().info_3_content_es,
      info_3_content_en: doc.data().info_3_content_en,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function infoKidsUpdateItem(data, id) {
  try {
    const response = await db
      .collection("home-info-kids-section")
      .doc(id)
      .set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function infoParentsUpdateItem(data, id) {
  try {
    const response = await db
      .collection("home-info-parents-section")
      .doc(id)
      .set(data);
    return response;
  } catch (error) {
    return error;
  }
}

// Gallery Services

export async function galleryImageAdd(data) {
  try {
    await db.collection("home-gallery-image-section").doc().set(data);
  } catch (error) {
    return error;
  }
}

export async function galleryUpdateItem(data, id) {
  try {
    const response = await db
      .collection("home-gallery-image-section")
      .doc(id)
      .set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getGalleryList() {
  try {
    const query = db.collection("home-gallery-image-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      image: doc.data().image,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getGalleryItem(id) {
  try {
    const query = await db.collection("home-gallery-image-section").doc(id);
    const querySnapshot = await query.get();
    const response = querySnapshot.data();
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteGallerylItem(id) {
  try {
    const response = await db
      .collection("home-gallery-image-section")
      .doc(id)
      .delete();
    return response;
  } catch (error) {
    return error;
  }
}

export async function galleryVideoAdd(data) {
  try {
    await db.collection("home-gallery-video-section").doc().set(data);
  } catch (error) {
    return error;
  }
}

export async function getGalleryVideosList() {
  try {
    const query = db.collection("home-gallery-video-section");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      video: doc.data().video,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteGalleryVideo(id) {
  try {
    const response = await db
      .collection("home-gallery-video-section")
      .doc(id)
      .delete();
    return response;
  } catch (error) {
    return error;
  }
}

// About Services

export async function getAbout(id) {
  try {
    const query = await db.collection("about-section").doc(id);
    const querySnapshot = await query.get();
    const response = querySnapshot.data();
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateAbout(data, id) {
  try {
    const response = await db.collection("about-section").doc(id).set(data);
    return response;
  } catch (error) {
    return error;
  }
}

// Services Services

export async function getServices(id) {
  try {
    const query = await db.collection("services-section").doc(id);
    const querySnapshot = await query.get();
    const response = querySnapshot.data();
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateServices(data, id) {
  try {
    const response = await db.collection("services-section").doc(id).set(data);
    return response;
  } catch (error) {
    return error;
  }
}

// Blog Services

export async function getBlogArticlesList() {
  try {
    const query = db.collection("blog-articles");
    const querySnapshot = await query.get();
    const docs = querySnapshot.docs;

    const response = docs.map((doc) => ({
      id: doc.id,
      title_es: doc.data().title_es,
      title_en: doc.data().title_en,
      sub_title_card_es: doc.data().sub_title_card_es,
      sub_title_card_en: doc.data().sub_title_card_en,
      content_1_es: doc.data().content_1_es,
      content_1_en: doc.data().content_1_en,
      content_2_es: doc.data().content_2_es,
      content_2_en: doc.data().content_2_en,
      content_3_es: doc.data().content_3_es,
      content_3_en: doc.data().content_3_en,
      content_4_es: doc.data().content_4_es,
      content_4_en: doc.data().content_4_en,
      image: doc.data().image,
      autor: doc.data().autor,
    }));
    return response;
  } catch (error) {
    return error;
  }
}

export async function createBlogArticle(data) {
  try {
    const response = await db.collection("blog-articles").doc().set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getBlogArticlesById(id) {
  try {
    const query = await db.collection("blog-articles").doc(id);
    const querySnapshot = await query.get();
    const response = querySnapshot.data();
    return response;
  } catch (error) {
    return error;
  }
}

export async function editBlogArticle(data, id) {
  try {
    const response = await db.collection("blog-articles").doc(id).set(data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteBlogArticle(id) {
  try {
    const response = await db.collection("blog-articles").doc(id).delete();
    return response;
  } catch (error) {
    return error;
  }
}

export async function createSkill(data) {
  try {
    const response = await axios({
      url: baseUrl + "/skill-section/create",
      method: "post",
      data: data,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getListSkills() {
  try {
    const response = await axios({
      url: baseUrl + "/skills-list",
      method: "get",
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteSkill(id) {
  try {
    const response = await axios({
      url: baseUrl + `/delete-skill/${id}`,
      method: "delete",
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getSkill(id) {
  try {
    const response = await axios({
      url: baseUrl + `/skill/${id}`,
      method: "get",
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateSkill(data, id) {
  try {
    const response = await axios({
      url: baseUrl + `/skill/update/${id}`,
      method: "put",
      data: data,
    });
    return response;
  } catch (error) {
    return error;
  }
}
