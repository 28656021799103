function storageAvailable(type) {
  try {
    const storage = window[type];
    const x = "__storage_test__";

    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function setLocalStorage(key, value) {
  if (!storageAvailable("localStorage")) {
    return false;
  }
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (domException) {
    return false;
  }
}

function getLocalStorage(key) {
  if (!storageAvailable("localStorage")) {
    return false;
  }

  return JSON.parse(window.localStorage.getItem(key));
}

function removeLocalStorage(key) {
  if (!storageAvailable("localStorage")) {
    return false;
  }

  window.localStorage.removeItem(key);

  return true;
}

function removeAllLocalStorage() {
  if (!storageAvailable("localStorage")) {
    return false;
  }

  window.localStorage.clear();

  return true;
}

export default {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  storageAvailable,
  removeAllLocalStorage,
};
