import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { FaWindowClose } from "react-icons/fa";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useDataPages } from "../../context/DataHome.js";
import { useEffect } from "react";

const SectionGalery = (props) => {
  const { dataGallery, dataGalleryVideo } = props;
  const apiUrl = process.env.REACT_APP_API_URL;
  const { lenguageSelected } = useDataPages();
  const [showImage, setShowImage] = useState(true);
  const [showVideos, setShowVideos] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showPhotos, setShowPhotos] = useState(true);
  const [showVideosGallery, setShowVideosGallery] = useState(true);

  const handleClickShowImage = (data) => {
    setImageUrl(data.image);
    setImageData(data);
    setShowImage(false);
  };

  const handleClickShowVideo = (urlVideo) => {
    setVideoUrl(urlVideo);
    setShowVideosGallery(false);
  };

  const handleClickImage = () => {
    setShowImage(true);
  };

  const handleClickVideo = () => {
    setShowVideosGallery(true);
  };

  const handleshowPhotos = () => {
    setShowPhotos(true);
    setShowVideos(false);
  };

  const handleshowVideos = () => {
    setShowPhotos(false);
    setShowVideos(true);
  };

  useEffect(() => {}, [showImage, showPhotos, showVideosGallery, showVideos]);

  return (
    <section id="gallery-section" className="gallery">
      <SectionGallery>
        <div className="container">
          {lenguageSelected === "es" ? (
            <h3 className="poppins">
              <span className="pink">g</span>a
              <span className="light-green">l</span>
              <span className="turquoise">e</span>
              <span className="pink">r</span>
              <span className="light-green">i</span>
              <span className="turquoise">a</span>
            </h3>
          ) : (
            <h3 className="poppins">
              <span className="pink">g</span>a
              <span className="light-green">l</span>
              <span className="turquoise">l</span>
              <span className="pink">e</span>r
              <span className="light-green">i</span>
              <span className="turquoise">e</span>
              <span className="pink">s</span>
            </h3>
          )}
          <div className="row gallery-nav">
            <div className="col-md-6">
              <a onClick={handleshowPhotos}>
                {lenguageSelected === "es" ? "Fotos" : "Photos"}{" "}
              </a>
            </div>
            <div className="col-md-6">
              <a onClick={handleshowVideos}>
                {lenguageSelected === "es" ? "Videos" : "Videos"}{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <>
              {showImage && showPhotos && (
                <div className="col-sm-12 text-center">
                  {dataGallery && (
                    <GalleryContainer>
                      {dataGallery.map((item, index) => (
                        <ImageContainer
                          onClick={() => handleClickShowImage(item)}
                          key={index}
                        >
                          <Image src={item.image} alt="" />
                        </ImageContainer>
                      ))}
                    </GalleryContainer>
                  )}
                </div>
              )}
              {!showImage && showPhotos && (
                <BigImageContainer onClick={handleClickImage}>
                  {dataGallery && (
                    <ImageWithTitle className="row">
                      <h3 className="col-md-12 text-center">
                        {lenguageSelected === "es"
                          ? imageData.title_es
                          : imageData.title_en}
                      </h3>
                      <img className="col-md-12" src={imageUrl} alt="" />
                    </ImageWithTitle>
                  )}
                </BigImageContainer>
              )}
              {showVideosGallery && showVideos && (
                <div className="col-sm-12 text-center">
                  {dataGalleryVideo && (
                    <GalleryContainer>
                      {dataGalleryVideo.map((item, index) => (
                        <VideoContainer
                          onClick={() => handleClickShowVideo(item.video)}
                          key={index}
                        >
                          <video width="300" src={item.video} alt="" />
                        </VideoContainer>
                      ))}
                    </GalleryContainer>
                  )}
                </div>
              )}
              {!showVideosGallery && showVideos && (
                <BigVideoContainer>
                  <Button variant="danger" onClick={handleClickVideo}>
                    <FaWindowClose />
                  </Button>
                  {dataGallery && <ReactPlayer url={videoUrl} controls />}
                </BigVideoContainer>
              )}
            </>
          </div>
        </div>
      </SectionGallery>
    </section>
  );
};

export default SectionGalery;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 80px;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px) {
    display: flex;
    padding: 30px;
    justify-content: space-between;
    align-items: center;
  }
`;

const Image = styled.img`
  margin: 20px;
`;

const SectionGallery = styled.div`
  margin-top: 150px;
`;

const ImageContainer = styled.div`
  width: 250px;
  transition: width 0.5s;
  margin: 20px;
  &:hover {
    width: 400px;
    z-index: 9999;
  }
  @media only screen and (max-width: 600px) {
    width: 300px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    &:hover {
      width: 700px;
      z-index: 9999;
    }
  }
`;

const VideoContainer = styled.div`
  width: 300px;

  @media only screen and (max-width: 600px) {
    width: 300px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    &:hover {
      width: 700px;
      z-index: 9999;
    }
  }
`;

const BigImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BigVideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageWithTitle = styled.div``;
