import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import {
  getInfoParentsList,
  infoParentsUpdateItem,
} from "../../../services/home.services";

import InputAdmin from "../../../components/Input/Input";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditInfoParents = (props) => {
  const methods = useForm();
  const reset = methods.reset;
  let navigate = useNavigate();
  const { id } = useParams();

  const onSubmit = (data) => {
    infoParentsUpdateItem(data, id).then((response) => {
      navigate("/list-info", { replace: true });
    });
  };

  const cancel = () => {
    navigate("/list-info", { replace: true });
  };

  useEffect(() => {
    getInfoParentsList().then((response) => {
      let data = response[0];
      reset({
        id: id,
        title_es: data.title_es,
        title_en: data.title_en,
        sub_title_color_1_es: data.sub_title_color_1_es,
        sub_title_color_1_en: data.sub_title_color_1_en,
        sub_title_color_2_es: data.sub_title_color_2_es,
        sub_title_color_2_en: data.sub_title_color_2_en,
        info_1_content_es: data.info_1_content_es,
        info_1_content_en: data.info_1_content_en,
        info_2_content_es: data.info_2_content_es,
        info_2_content_en: data.info_2_content_en,
        info_3_content_es: data.info_3_content_es,
        info_3_content_en: data.info_3_content_en,
      });
    });
  }, [reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Title>Editar Informacion de Padres</Title>
          <FormContainer className="row">
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Ingles"}
                fsLabel={"2rem"}
                inputName={`title_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Titulo Español"}
                fsLabel={"2rem"}
                inputName={`title_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Sub-Titulo color 1 Ingles"}
                fsLabel={"2rem"}
                inputName={`sub_title_color_1_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Sub-Titulo color 1 Español"}
                fsLabel={"2rem"}
                inputName={`sub_title_color_1_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Sub-Titulo color 2 Ingles"}
                fsLabel={"2rem"}
                inputName={`sub_title_color_2_en`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Sub-Titulo color 2 Español"}
                fsLabel={"2rem"}
                inputName={`sub_title_color_2_es`}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Contenido 1 Ingles"}
                fsLabel={"2rem"}
                inputName={"info_1_content_en"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Contenido 1 Español"}
                fsLabel={"2rem"}
                inputName={"info_1_content_es"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Contenido 2 Ingles"}
                fsLabel={"2rem"}
                inputName={"info_2_content_en"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Contenido 2 Español"}
                fsLabel={"2rem"}
                inputName={"info_2_content_es"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Contenido 3 Ingles"}
                fsLabel={"2rem"}
                inputName={"info_3_content_en"}
              ></InputAdmin>
            </div>
            <div className="col-md-6">
              <InputAdmin
                label={"Contenido 3 Español"}
                fsLabel={"2rem"}
                inputName={"info_3_content_es"}
              ></InputAdmin>
            </div>
            <div className="col-md-2">
              <InputAdmin
                label={"Id"}
                fsLabel={"2rem"}
                inputName={"id"}
                disabled={true}
              ></InputAdmin>
            </div>
          </FormContainer>
          <ButtonArea>
            <Button className="mt-1" onClick={cancel} variant="light">
              Cancelar
            </Button>
            <Button className="mt-1" variant="danger" type="submit">
              Guardar
            </Button>
          </ButtonArea>
        </Form>
      </FormProvider>
    </>
  );
};

export default EditInfoParents;

const Title = styled.h2`
  text-align: center;
  margin-top: 150px;
`;

const FormContainer = styled.div`
  padding: 50px 300px;
`;

const ButtonArea = styled.div`
  margin-bottom: 50px;
  padding: 0px 300px;
  display: flex;
  justify-content: end;
`;
